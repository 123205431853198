import "./App.scss";
import axios from "axios";
import logo from "./Assets/images/the-tul.png";
import { createContext, useState, useEffect } from "react";
import jsonview from "@pgrabovets/json-view";
import GbTracker from "gb-tracker-client";
import ReactSwitch from "react-switch";

export const ThemeContext = createContext(null);

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [searchRequest, setSearchRequest] = useState("");
  const [clientKey, setClientKey] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [loginID, setLoginID] = useState("");
  const [area, setArea] = useState("");

  const onOneTapSignedIn = (response) => {
    setIsSignedIn(true);
  };

  /* global google*/
  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        "1070202512491-7btehirt4sfgmcgcvauceih2upd7052f.apps.googleusercontent.com",
      callback: onOneTapSignedIn,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });

    google.accounts.id.prompt((notification) => {
      console.log("on prompt notification".notification);
      if (notification.isNotDisplayed()) {
        console.log(notification.getNotDisplayedReason());
      } else if (notification.isSkippedMoment()) {
        console.log(notification.getSkippedReason());
      } else if (notification.isDismissedMoment()) {
        console.log(notification.getDismissedReason());
      }
    });
  });

  var jsonData = `${searchRequest}`;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      "skip-cache": "true",
      "X-Groupby-Customer-Id": `${customerId}`,
      Authorization: `client-key ${clientKey}`,
    },
  };

  function searchCall(e) {
    document.getElementById("search-res").innerHTML = "";
    document.getElementById("beacon-res").innerHTML = "";
    e.preventDefault();
    const gbTracker = new GbTracker(`${customerId}`, `${area}`);
    if(loginID == "" || loginID == null) {
      gbTracker.autoSetVisitor();
    } else {
      gbTracker.autoSetVisitor(loginID);
    }
    axios
      .post(
        `https://search.${
          document.getElementById("checkbox1").checked
            ? customerId + "-lo"
            : customerId
        }.groupbycloud.com/api/search/`,
        jsonData,
        axiosConfig
      )
      .then((res) => {
        const tree = jsonview.create(res);
        jsonview.render(tree, document.getElementById("search-res"));
        jsonview.expand(tree);
        gbTracker.sendAutoSearchEvent({
          search: {
            id: `${res["data"]["id"]}`,
            origin: {
              recommendations: false,
              navigation: false,
              dym: false,
              sayt: false,
              search: true,
            },
          },
        });
        const beacon_tree = jsonview.create({
          search: {
            id: `${res["data"]["id"]}`,
            origin: {
              recommendations: false,
              navigation: false,
              dym: false,
              sayt: false,
              search: true,
            },
          },
        });
        jsonview.render(beacon_tree, document.getElementById("beacon-res"));
        jsonview.expand(beacon_tree);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const clear = () => {
    document.getElementById("input-custId").value = "";
    document.getElementById("input-area").value = "";
    document.getElementById("input-clientKey").value = "";
    document.getElementById("input-request").value = "";
    document.getElementById("search-res").innerHTML = "";
    document.getElementById("beacon-res").innerHTML = "";
  };

  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {isSignedIn ? (
        <div className="App" id={theme}>
          <div className="background">
            <div className="red-line top-rotate"></div>
            <div className="white-line top-secondary-rotate"></div>
            <div className="header" onClick={clear}>
              <img className="logo" src={logo} alt="" />
            </div>
            <div className="switch">
              <p className="switch-label">Theme: </p>
              <ReactSwitch onChange={toggleTheme} checked={theme === "dark"} />
            </div>
            <div className="input">
              <button className="header-button" onClick={clear}>
                Clear Inputs
              </button>
              <div className="input-top">
                <form className="input-form">
                  <input
                    type="text"
                    className="input-custId"
                    id="input-custId"
                    placeholder="Customer ID"
                    onChange={(e) => setCustomerId(e.target.value)}
                  />
                  <input
                    type="text"
                    className="input-area"
                    id="input-area"
                    placeholder="Area"
                    onChange={(e) => setArea(e.target.value)}
                  />
                  <input
                    type="password"
                    className="input-clientKey"
                    id="input-clientKey"
                    placeholder="Client Key"
                    onChange={(e) => setClientKey(e.target.value)}
                  />
                  <input
                    type="text"
                    className="input-loginID"
                    id="input-loginID"
                    placeholder="Login ID"
                    onChange={(e) => setLoginID(e.target.value)}
                  />
                </form>
              </div>
              <div className="checkbox">
                <input id="checkbox1" type="checkbox" />
                <label className="checkbox-text" for="checkbox1">
                  -lo?
                </label>
              </div>
              <div className="input-bottom">
                <textarea
                  onChange={(e) => setSearchRequest(e.target.value)}
                  className="input-request"
                  id="input-request"
                  cols="30"
                  rows="8"
                  placeholder="Search JSON body"
                ></textarea>
              </div>
              <button onClick={searchCall} className="submit">
                Send
              </button>
            </div>
            <div className="body">
              <div className="body-left">
                <p className="body-left__title">Search Response</p>
                <div className="display-json" id="search-res"></div>
              </div>
              <div className="body-right">
                <p className="body-right__title">Beacon Request</p>
                <div className="display-json" id="beacon-res"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <div className="App" id={theme}>
            <div className="background">
              <div className="red-line top-rotate"></div>
              <div className="white-line top-secondary-rotate"></div>
              <div className="header">
                <a href="/">
                  <img className="logo" src={logo} alt="" />
                </a>
              </div>
              <div className="sign-in">
                <h1>Please sign in to use the Tül: </h1>
                <div id="signInDiv"></div>
              </div>
            </div>
          </div>
        </ThemeContext.Provider>
      )}
    </ThemeContext.Provider>
  );
}

export default App;
